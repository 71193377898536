import Image from "next/image";
import homeIcon from "../../../../public/statics/images/homeIcon.png";

export default function SignInTitle() {
  return (
    <div className="flex flex-col items-center justify-center gap-2 text-center sm:gap-4 bg-black">
      <Image
        src={homeIcon}
        alt="home icon"
        className="h-14 w-14 sm:h-28 sm:w-28"
      />
      <div className="flex flex-col items-center space-y-4 text-center">
        <h1 className="text-5xl font-bold tracking-tight sm:text-6xl">
          <span className="text-white">Clone Factory</span>
        </h1>
        <p className="text-lg font-light sm:text-xl text-white">
          부캐공장 - AI 캐릭터 숏폼
        </p>
      </div>
    </div>
  );
}