"use client";

import RoundIconButtonWithBorder from "@/components/common/buttons/RoundIconButtonWithBorder";
import TextLinkButton from "@/components/common/buttons/TextLinkButton";
import DownloadAppToast from "@/components/common/toasts/DownloadAppToast";
import SignInButton from "@/components/domain/auth/SignInButton";
import SignInTitle from "@/components/domain/auth/SignInTitle";
import { PREV_PATH } from "@/constants/constants";
import { logGaEvent } from "@/service/gtag";
import { AUTH_TOKEN, setItemInLocalStorage } from "@/service/localStorage";
import { ClientSafeProvider, signOut, useSession } from "next-auth/react";
import { redirect, useSearchParams } from "next/navigation";
import { useEffect, useRef, useState, useCallback } from "react";
import { isMobile } from "react-device-detect";
import { BsChevronDoubleDown } from "react-icons/bs";
import { Element, scroller } from "react-scroll";
import { Suspense } from "react";
import AuthErrorChecker from "./AuthErrorChecker";
import IntroductionTitle from "@/components/domain/introduction/IntroductionTitle";
import ChatExample from "@/components/domain/introduction/ChatExample";
import Advantages from "@/components/domain/introduction/Advantages";
import AiTherapistDesc from "@/components/domain/introduction/AiTherapistDesc";
import ConsultationTechniques from "@/components/domain/introduction/ConsultationTechniques";

interface Props {
  providers: Record<string, ClientSafeProvider> | null;
}

const introductionElement = "introduction";

export default function SignInPage({ providers }: Props) {
  const [scrolledDown, setScrolledDown] = useState(false);
  const { data: session } = useSession();
  const isAuth = session && !session.error;
  const [downloadAppToastShowed, setDownloadAppToastShowed] = useState(false);
  const downloadAppToastShowedRef = useRef<boolean>(false);
  const introRef = useRef<HTMLDivElement>(null);
  const [isExposed, setIsExposed] = useState(false);

  const handleScroll = () => {
    const targetElement = introRef.current;
    if (targetElement) {
      const elementTop = targetElement.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      setIsExposed(elementTop < windowHeight);
    }
  };

  useEffect(() => {
    if (isExposed)
      logGaEvent({
        action: "seemore_show",
      });
  }, [isExposed]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const prevPath = globalThis.sessionStorage.getItem(PREV_PATH);
    if (isAuth) {
      logGaEvent({
        action: session.user.isNewAccount
          ? "signup_complete"
          : "signin_complete",
      });
      setItemInLocalStorage(AUTH_TOKEN, session.user.token);
      redirect(prevPath ? prevPath : "/");
    }
  }, [isAuth, session]);

  const scrollToIntroduction = () => {
    scroller.scrollTo(introductionElement, {
      duration: 800,
      delay: 0,
      smooth: true
    });
  };

  return (
    <main className="flex min-h-screen w-full flex-col items-center bg-black">
      <div className="flex w-full flex-col items-center px-3 min-h-screen">
        <Suspense fallback={<div>Loading...</div>}>
          <AuthErrorChecker />
        </Suspense>
        <TextLinkButton
          title=" "
          href="mailto:contact@tooeasy.life"
          target="_blank"
        />
        {/* 상단 여백을 flex-1로 조정하여 타이틀을 수직 중앙으로 */}
        <div className="flex-1" />
        <div className="flex items-end justify-center mb-12">
          <SignInTitle />
        </div>
        <div className="flex flex-1 items-center justify-center flex-col">
          <SignInButton providers={providers} />
          <button
            onClick={scrollToIntroduction}
            className="mt-12 text-white flex items-center gap-2 hover:opacity-80"
          >
            더 알아보기
            <BsChevronDoubleDown />
          </button>
        </div>
        {/* 하단 여백 추가 */}
        <div className="flex-1" />
      </div>

      <Element name={introductionElement}>
        {/* 소개 섹션에 좌우 여백과 섹션 간 간격 추가 */}
        <div ref={introRef} className="w-full max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="space-y-24 py-16"> {/* 섹션 간 간격 추가 */}
            <IntroductionTitle />
            <ChatExample />
            <Advantages />
            <AiTherapistDesc />
            <ConsultationTechniques />
          </div>
        </div>
      </Element>
    </main>
  );
}